<template>
  <div>
    <div v-if="data" class="row justify-center q-ma-md">
      <q-card
        v-for="product in data.items"
        :key="product.item"
        class="col-xs-12 col-sm-3 q-ma-sm"
      >
        <q-card-section class="q-pa-none">
          <div class="text-h6 text-center">Item {{ product.item }}</div>
        </q-card-section>
        <q-separator size="1px" color="black" />
        <q-card-section>
          <div class="col-12">
            <span class="text-bold">{{
              `${product.productCode} - ${product.productDescription}`
            }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Unidade: </span>
            <span>{{ product.um }}</span>
            <span class="text-bold"> | Quantidade: </span>
            <span>{{ product.quantity }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Entrega: </span>
            <span>{{ format(product.deliveryDate, "date") }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">CC: </span>
            <span>{{ data.costCenter }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Obs.: </span>
            <span>{{ product.note }}</span>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import formatString from "../../helpers/formatString";
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    format(value, type) {
      return formatString(value, type);
    }
  }
};
</script>
